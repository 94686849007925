import React from "react";
//import { Link } from "react-router-dom";

import { Col, Row } from "antd";
/*
import styles from "../../styles/Header.module.css";

const menu_items = [
  {
    title: "Overview",
    url: "/overview",
  },
  {
    title: "Design",
    url: "/design",
  },
  {
    title: "Learn",
    url: "/learn",
  },
  {
    title: "Support",
    url: "/support",
  },
];*/

const Header = (props) => (
  <Row>
    <Col span={24}>
    </Col>
  </Row>
);

export default Header;
