import React from "react";

//import ichb_logo from "../../images/ichb_logo.jpg";
import styles from "../../styles/Footer.module.css";

const Footer = (props) => (
  <div className={styles.footer_div}>
  </div>
);

export default Footer;
