import React from "react";

import Header from "./Header.js";
import Footer from "./Footer.js";

import { Col, Row } from "antd";

import styles from "../../styles/Layout.module.css";

const Layout = (props) => (
  <div className={styles.layout_main_div}>
    <Header />
    <Row>
      <div style={{ height: "60px" }}></div>
    </Row>
    <Row>
      <Col span={24} offset={0} className={styles.layout_main_column}>
        <Row>
          <div style={{ height: "50px" }}></div>
        </Row>
        <Row>
          <Col span={24}>
            <main>{props.children}</main>
          </Col>
        </Row>
        <Row>
          <div style={{ height: "50px" }}></div>
        </Row>
      </Col>
    </Row>
    <Row>
      <div style={{ height: "100px" }}></div>
    </Row>
    <Footer />
  </div>
);

export default Layout;
