const get_data_from_phrase = async (phrase) => {
  const p = phrase.trim();

  const url =
    "https://rest.ensembl.org/xrefs/symbol/human/" +
    encodeURIComponent(p) +
    "?content-type=application/json";

  const result = { data: [] };

  await fetch(url)
    .then((response) => response.json())
    .then((data) => {
      const res = data.filter(
        (v) => v.type === "gene" || v.type === "transcript"
      );
      result.data = res;
    });

  return result;
};
/*
const get_transcripts_for_gene = async (id) => {
  const url =
    "https://rest.ensembl.org//lookup/id/" +
    id +
    "?species=homo_sapiens&content-type=application/json&expand=1";

  let result = [];

  await fetch(url)
    .then((response) => response.json())
    .then((data) => {
      result = data.Transcript;
    });

  return result;
};*/

const get_data_for_xids_list = async (xids) => {
  const url = "https://rest.ensembl.org/lookup/symbol/homo_sapiens";

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ symbols: xids }),
  };

  let result = {};

  await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      result = data;
    });

  return result;
};

const get_data_for_stable_id = async (id) => {
  const url =
    "https://rest.ensembl.org//lookup/id/" +
    id +
    "?species=homo_sapiens&content-type=application/json&expand=1";

  let result = {};

  await fetch(url)
    .then((response) => response.json())
    .then((data) => {
      result = data;
    });

  return result;
};

const get_transcript_sequence = async (id) => {
  const url =
    "https://rest.ensembl.org/sequence/id/" +
    id +
    "?content-type=application/json&object_type=transcript&species=human&type=cds";

  let result = {};

  await fetch(url)
    .then((response) => response.json())
    .then((data) => {
      result = data;
    });

  return result.seq;
};

export {
  get_data_from_phrase,
  get_data_for_xids_list,
  get_data_for_stable_id,
  get_transcript_sequence,
};
