import React from "react";

import { Table, Row, Button, Col, Spin, Collapse, Form } from "antd";

import { with_params } from "../utils/params";

import styles from "../styles/Details.module.css"

import {
  get_data_for_stable_id,
  get_transcript_sequence,
} from "../utils/search_machine";

//import example_transcripts from "../utils/example_transcripts";

import { parameters, render_parameter } from "../utils/parameters";

//import styles from "../styles/Details.module.css";

const create_hyperlink_for_transcript = (stable_id) => {
  const url =
    "https://www.ensembl.org/Homo_sapiens/Transcript/Summary?t=" + stable_id;

  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      {stable_id}
    </a>
  );
};

class Details extends React.Component {
  state = {
    transcripts: [],
    chosen_id: "",
    waiting: true,
  };

  formRef = React.createRef();

  retrieveData = () => {
    const stable_id = this.props.params.id;
    get_data_for_stable_id(stable_id).then((data) => {
      let transcripts = data;
      if (data.object_type === "Gene") {
        transcripts = transcripts.Transcript;
      } else {
        //"Transcript"
        transcripts = [transcripts];
      }

      transcripts = transcripts.filter((v) => v.biotype === "protein_coding");

      transcripts = transcripts.map((v) => {
        v.key = v.id;
        let l = 0;
        for (let i in v.Exon) {
          l += v.Exon[i].end + 1 - v.Exon[i].start;
        }
        v.len = l;

        return v;
      });

      this.setState({ transcripts, waiting: false });
    });
  };

  componentDidMount = () => {
    this.retrieveData();
  };

  choose_clicked = (e, d) => {
    e.preventDefault();

    this.setState({ chosen_id: d.id });
    /*
    let hash = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 32; i++) {
      hash += characters.charAt(Math.floor(Math.random() * characters.length));
    }*/

    get_transcript_sequence(d.id).then((seq) => {
      const navigate = this.props.navigate;

      const params = this.formRef.current.getFieldsValue(true);
      navigate("/results", { state: { seq, params } });
    });
  };

  render = () => {
    const columns = [
      {
        title: "Stable ID",
        dataIndex: "id",
        key: "stable_id",
        render: (text) => create_hyperlink_for_transcript(text),
        sorter: (a, b) => a.id.localeCompare(b.id),
      },
      {
        title: "Start",
        dataIndex: "start",
        key: "start",
        sorter: (a, b) => a.start - b.start,
      },
      {
        title: "End",
        dataIndex: "end",
        key: "end",
        sorter: (a, b) => a.end - b.end,
      },
      {
        title: "Length",
        dataIndex: "len",
        key: "len",
        sorter: (a, b) => a.len - b.len,
      },
      {
        title: "Name",
        dataIndex: "display_name",
        key: "display_name",
        sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      },
      {
        title: "Exons",
        dataIndex: "Exon",
        key: "Exon",
        render: (arr) => arr.length,
        sorter: (a, b) => a.Exon.length - b.Exon.length,
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        sorter: (a, b) => a.version - b.version,
      },
      {
        title: "Choose",
        dataIndex: "choose",
        key: "choose",
        render: (text, record) => (
          <Button
            type="primary"
            onClick={(e) => this.choose_clicked(e, record)}
            loading={this.state.chosen_id === record.id}
            disabled={this.state.chosen_id !== ""}
            className={styles.choose_button}
          >
            Choose
          </Button>
        ),
      },
    ];

    const initialValues = {};

    for (const p of parameters) {
      initialValues[p.id] = p.value;
    }

    return (
      <Row>
        <Col span={22} offset={1}>
          <h1>
            Transcripts for <b>{this.props.params.id}</b>
          </h1>
          {this.state.waiting ? (
            <React.Fragment>
              <Row>
                <div style={{ height: "100px" }}></div>
              </Row>
              <Row>
                <Spin
                  style={{ width: "100%" }}
                  className="ant-spin-vlg"
                  tip="Loading..."
                />
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Row>
                <Table
                  style={{ width: "100%" }}
                  columns={columns}
                  dataSource={this.state.transcripts}
                />
              </Row>
              <Row>
                <Collapse style={{ width: "100%" }}>
                  <Collapse.Panel
                    header="Advanced parameters"
                    key="1"
                    forceRender={true}
                  >
                    <Col span={22} offset={1}>
                      <Form
                        initialValues={initialValues}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        ref={this.formRef}
                      >
                        <Row gutter={24}>
                          {parameters.map((v) => (
                            <Col span={12} key={v.id}>
                              <Form.Item
                                label={
                                  <div title={v.comment}>{v.display_name}</div>
                                }
                                title={v.comment}
                                name={v.id}
                              >
                                {render_parameter(v)}
                              </Form.Item>
                            </Col>
                          ))}
                        </Row>
                      </Form>
                    </Col>
                  </Collapse.Panel>
                </Collapse>
              </Row>
            </React.Fragment>
          )}
        </Col>
      </Row>
    );
  };
}

export default with_params(Details);
