import React from "react";

import { Row, Input, Form, Button, Col, Collapse, Checkbox } from "antd";

import { parameters, render_parameter } from "../utils/parameters";

import { with_params } from "../utils/params";

class Home extends React.Component {
  state = {
    seq_validate_status: "",
    target_validate_status: [""],
    file_uploaded: false,
    duplexes: [{ guide: "", passenger: "", compl: true }],
  };

  process_fasta = (text, params) => {
    text = text.split(/\r?\n/);

    let id = false;
    let seq = "";

    for (let line of text) {
      line = line.trim();
      if (
        line.length === 0 ||
        line.charAt(0) === ";" ||
        line.charAt(0) === ">"
      ) {
        if (id) break;
        else continue;
      }
      id = true;

      seq += line;
    }
    //console.log(seq);

    const navigate = this.props.navigate;

    navigate("/results", { state: { seq, params } });
  };

  onSubmit = (data) => {
    const params = {};

    for (const p of parameters) {
      params[p.id] = data[p.id];
    }

    const duplexes = [];

    for (const v of this.state.duplexes) {
      if (v.guide.length === 0 && v.passenger.length === 0) {
        continue;
      }
      if (!/^[AaCcGgTtUu\s]+$/.test(v.guide)) {
        alert("The guide sequence " + v.guide + " contains an unkown symbol!");
        return;
      }
      if (!/^[AaCcGgTtUu\s]+$/.test(v.passenger)) {
        alert(
          "The passenger sequence " +
            v.passenger +
            " contains an unkown symbol!"
        );
        return;
      }

      duplexes.push({
        guide: v.guide.toUpperCase().replaceAll("T", "U"),
        passenger: v.passenger.toUpperCase().replaceAll("T", "U"),
      });
    }

    const navigate = this.props.navigate;

    navigate("/results", { state: { duplexes, params } });
  };

  onChange = (info) => {
    if (info.fileList.length === 0) {
      this.setState({ file_uploaded: false });
    } else {
      this.setState({ file_uploaded: true });
    }
  };

  textAreaOnChange = (e) => {
    const text = e.target.value;
    let seq_validate_status = "";

    if (!/^[AaCcGgTtUu\s]+$/.test(text)) {
      seq_validate_status = "error";
    }

    this.setState({ seq_validate_status });
  };

  textAreaOnChange_target = (e) => {
    const text = e.target.value;
    let target_validate_status = "";

    if (
      !/^[AaCcGgTtUu]{20,24}([^AaCcGgTtUu]+[AaCcGgTtUu]{20,24})*[^AaCcGgTtUu]*$/.test(
        text
      )
    ) {
      target_validate_status = "error";
    }

    this.setState({ target_validate_status });
  };

  complementary = (seq) => {
    seq = seq.trim().toUpperCase().replaceAll("T", "U");

    let result = "";

    const dic = {
      A: "U",
      C: "G",
      G: "C",
      U: "A",
    };

    for (let i = seq.length - 1; i >= 0; --i) {
      const nuc = seq.charAt(i);

      if (dic.hasOwnProperty(nuc)) {
        result += dic[nuc];
      } else {
        result += "N";
      }
    }

    return result;
  };

  guideChanged = (e, ind) => {
    const new_duplexes = this.state.duplexes.map((v, i) => {
      if (i === ind) {
        return {
          ...v,
          guide: e.target.value,
          passenger: v.compl ? this.complementary(e.target.value) : v.passenger,
        };
      } else {
        return v;
      }
    });

    if (ind === new_duplexes.length - 1) {
      new_duplexes.push({ guide: "", passenger: "", compl: true });
    }

    this.setState({ duplexes: new_duplexes });
  };

  passengerChanged = (e, ind) => {
    const new_duplexes = this.state.duplexes.map((v, i) => {
      if (i === ind) {
        return {
          ...v,
          passenger: e.target.value,
        };
      } else {
        return v;
      }
    });

    if (ind === new_duplexes.length - 1) {
      new_duplexes.push({ guide: "", passenger: "", compl: true });
    }

    this.setState({ duplexes: new_duplexes });
  };

  complementaryChanged = (e, ind) => {
    const new_duplexes = this.state.duplexes.map((v, i) => {
      if (i === ind) {
        return {
          ...v,
          compl: e.target.checked,
          passenger: this.complementary(v.guide),
        };
      } else {
        return v;
      }
    });

    this.setState({ duplexes: new_duplexes });
  };

  render = () => {
    const initialValues = {};

    for (const p of parameters) {
      initialValues[p.id] = p.value;
    }

    return (
      <Row>
        <Col span={22} offset={1}>
          <Row>
            <h1>Duplexes</h1>
          </Row>
          <Row>
            <div style={{ height: "100px" }}></div>
          </Row>
          <Row>
            <Form
              style={{ width: "100%" }}
              onFinish={this.onSubmit}
              initialValues={initialValues}
            >
              <Form.Item
                validateStatus={
                  this.state.seq_validate_status === "error" &&
                  this.state.target_validate_status === "error"
                    ? "error"
                    : ""
                }
              >
                {this.state.duplexes.map((v, i) => (
                  <Row key={i}>
                    <Col span={5}>
                      <Input
                        showCount={false}
                        onChange={(e) => this.guideChanged(e, i)}
                        value={this.state.duplexes[i].guide}
                      />
                    </Col>
                    <Col span={5} offset={1}>
                      <Input
                        showCount={false}
                        onChange={(e) => this.passengerChanged(e, i)}
                        value={this.state.duplexes[i].passenger}
                        disabled={this.state.duplexes[i].compl}
                      />
                    </Col>
                    <Col span={5} offset={1}>
                      <Checkbox
                        checked={this.state.duplexes[i].compl}
                        onChange={(e) => this.complementaryChanged(e, i)}
                      >
                        Complementary passenger
                      </Checkbox>
                    </Col>
                  </Row>
                ))}
              </Form.Item>
              {/*<Form.Item
                label="Targets"
                name="targets"
                validateStatus={this.state.target_validate_status}
              >
                <Input.TextArea
                  showCount={true}
                  onChange={this.textAreaOnChange_target}
                />
              </Form.Item>*/}
              {/*<Form.Item
                label="Fasta file"
                name="fasta"
                valuePropName="filelist"
              >
                <Upload
                  maxCount={1}
                  onChange={this.onChange}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>*/}
              <Form.Item wrapperCol={{ span: 4, offset: 10 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    this.state.seq_validate_status === "error" &&
                    this.state.target_validate_status === "error" &&
                    !this.state.file_uploaded
                  }
                >
                  Submit
                </Button>
              </Form.Item>
              <Collapse style={{ width: "100%" }}>
                <Collapse.Panel
                  header="Advanced parameters"
                  key="1"
                  forceRender={true}
                >
                  <Col span={22} offset={1}>
                    <Row gutter={24}>
                      {parameters.map((v) => (
                        <Col span={12} key={v.id}>
                          <Form.Item
                            label={
                              <div title={v.comment}>{v.display_name}</div>
                            }
                            title={v.comment}
                            name={v.id}
                          >
                            {render_parameter(v)}
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Collapse.Panel>
              </Collapse>
            </Form>
          </Row>
        </Col>
      </Row>
    );
  };
}

export default with_params(Home);
