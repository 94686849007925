const convertToCSVFormat = (str) => {
  let res = str.toString();
  let quotes = !/^[\x20\x21\x23-\x2b\x2d-\x7e]*$/.test(res); //RFC 4180 text without separator (comma) and any special characters

  if (quotes) {
    res = res.replace(/"/, '""');
    res = '"' + res + '"';
  }

  return res;
};

const generateTextFile = (text, filename) => {
  let dlink = document.createElement("a");
  dlink.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  dlink.setAttribute("download", filename);

  if (document.createEvent) {
    /*var event = document.createEvent("MouseEvents");
    event.initEvent("click", true, true);*/

    const event = new MouseEvent("click")

    dlink.dispatchEvent(event);
  } else {
    dlink.click();
  }
};

export { convertToCSVFormat, generateTextFile };
