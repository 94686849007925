import React from "react";

import { InputNumber, Select, Checkbox } from "antd";

const parameters = [
  {
    id: "5prime_diff_len",
    display_name: "Bps to init duplex dissociation",
    type: "integer",
    min: 0,
    max: 10,
    step: 1,
    value: 3,
    comment:
      "The number of nucleotides which are checked on both ends to determine if the duplex will start to dissociate from the correct side",
  },
];

const render_parameter = (par) => {
  switch (par.type) {
    case "integer":
      return (
        <InputNumber
          min={par.min}
          max={par.max}
          step={par.step ? par.step : 1}
        />
      );
    case "float":
      return (
        <InputNumber
          min={String(par.min)}
          max={String(par.max)}
          step={String(par.step)}
          stringMode
        />
      );
    case "select":
      return <Select options={par.options} />;
    case "boolean":
      return <Checkbox defaultChecked={par.value}></Checkbox>
    default:
      return null;
  }
};

export { parameters, render_parameter };
