import React from "react";
import Layout from "./components/layout/Layout.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home.js";
import Details from "./pages/Details.js";
import Results from "./pages/Results.js";

import "./App.css";

class App extends React.Component {
  state = {
    sequence: "",
  };

  render = () => {
    return (
      <Router>
        <Layout>
          <Routes>
            <Route exact path="/details/:id" element={<Details />} />
            <Route exact path="/results" element={<Results />} />
            <Route exact path="/" element={<Home />} />
          </Routes>
        </Layout>
      </Router>
    );
  };
}

export default App;
